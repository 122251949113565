import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import {
  Grid,
  Link,
  Margin,
  MoreBug,
  PageWrapper,
  Section,
} from "@components/atoms"
import { GridItem } from "@components/molecules"
import { TransitionMask } from "@components/organisms"
import { Description } from "@components/molecules/GridItem"
import { PageProps } from "@types"
import { palette } from "@theme"
import { useDisplayMode, DisplayMode } from "@utils"

const PlayPage = ({
  data: {
    sanityPage: { title, description, gridItems },
  },
}: PageProps) => {
  const { displayMode } = useDisplayMode()
  const parsedTitle = "Franklyn: " + title
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]
  return (
    <PageWrapper>
      <Helmet {...{ meta }} title={parsedTitle}>
        <title>{parsedTitle}</title>
      </Helmet>
      <Margin>
        <Section title={title} noPadding>
          <Grid noPadding wideMobileRows>
            {gridItems
              .filter(p => !!p)
              .map(item => (
                <GridItem key={item._key} {...item}>
                  <Copy>
                    {item.post && (
                      <>
                        <MoreBug to={`/${item.post.slug.current}`}>
                          {item.post?.postType === "thoughts" ? (
                            <span>
                              Read full
                              <br />
                              article
                            </span>
                          ) : (
                            <span>
                              Click
                              <br />
                              for more
                            </span>
                          )}
                        </MoreBug>
                        <StyledLink to={`/${item.post.slug.current}`}>
                          {item.date && <ParsedDate>{item.date}</ParsedDate>}
                          {/* <PostType className="linkHighlight" {...{displayMode}} >{item.post?.postType|| 'Sketches'}&nbsp;</PostType> */}
                          <StyledDescription>
                            {item.post?.extraThoughts?.author.name ||
                              item.post?.title}
                          </StyledDescription>
                        </StyledLink>
                      </>
                    )}
                    {item.link && (
                      <StyledLink to={`/${item.link}`}>
                        {item.date && <ParsedDate>{item.date}</ParsedDate>}
                        {/* <PostType
                          className="linkHighlight"
                          {...{ displayMode }}
                        >
                          Sketches&nbsp;
                        </PostType> */}
                        <StyledDescription>
                          {item.description}
                        </StyledDescription>
                      </StyledLink>
                    )}
                    {!item.post && !item.link && (
                      <>
                        {item.date && <ParsedDate>{item.date}</ParsedDate>}
                        {/* <PostType
                          className="linkHighlight"
                          {...{ displayMode }}
                        >
                          Sketches&nbsp;
                        </PostType> */}
                        <StyledDescription>
                          {item.description}
                        </StyledDescription>
                      </>
                    )}
                  </Copy>
                </GridItem>
              ))}
          </Grid>
        </Section>
      </Margin>
      <TransitionMask />
    </PageWrapper>
  )
}

const Copy = styled.div`
  font-size: 1.8rem;
  line-height: 1.4em;
  font-family: arial;
  @media only screen and (min-width: 744px) {
    font-size: 2.1rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 2.4rem;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  &:hover > h4 {
    color: ${palette.lime};
  }
  &:hover > h3 {
    color: ${palette.funGreen};
  }
`

const ParsedDate = ({ children }: { children: string }) => {
  // todo: I think this is redundant to utils/prettyDate()
  const realDate = new Date(Date.parse(children))
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  return (
    <DateWrapper>
      {realDate.getDate()} {months[realDate.getMonth()]}{" "}
      {realDate.getFullYear()}
    </DateWrapper>
  )
}

const DateWrapper = styled.div`
  font-family: "AkkuratMono", monospace;
  font-size: 1rem;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  @media only screen and (min-width: 744px) {
    font-size: 1.25rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 1.4rem;
  }
  color: ${palette.dove};
`

const PostType = styled.h4<{ displayMode: DisplayMode }>`
  color: ${props =>
    props.displayMode === "dark" ? palette.white : palette.black};
  transition: color 0.25s ease-in-out;
  display: inline;
  text-transform: capitalize;
`

const StyledDescription = styled(props => <Description {...props} />)`
  display: inline;
  transition: color 0.25s ease-in-out;
`

export const query = graphql`
  query PlayPageQuery {
    sanityPage(slug: { current: { eq: "lately" } }) {
      ...pageFields
    }
  }
`

export default PlayPage
